<template>
  <div
    class="public-source-form-container"
    v-if="
      (+platInfo.accessType === 1 &&
        (+positionId === 1 ||
        +positionId === 3 ||
        (+positionId === 10 && (+formquery.adStyle === 1 ||+formquery.adStyle === 2)) ||
        +positionId === 6 || +positionId === 6)) ||
        ((((+positionId === 1 || +positionId === 3) && +formquery.isAutoDrawing === 1 && showIsAutoDrawing(formquery, positionId, formquery.platId)) ||
        +positionId === 4 ||
        (+positionId === 10 && +formquery.adStyle === 2)) &&
        (+formquery.platId === 1 ||
        +formquery.platId === 2 ||
        +formquery.platId === 3 ||
        +formquery.platId === 4 ||
        +formquery.platId === 6 ||
        +formquery.platId === 10 ||
        +formquery.platId === 12 ||
        +formquery.platId === 14 ||
        +formquery.platId === 19)) ||
        (+positionId === 10 && +formquery.adStyle === 2 && +formquery.platId === 0) ||
        (+positionId === 5 && +formquery.platId === 0) ||
        (+positionId === 2 && +formquery.platId === 0)
    "
  >
    <!-- 广告交互 -->
    <!-- API条件 -->
    <!--
      API广告 && (开屏 || 插屏 || 混合模版 || 原生模版)
     -->
    <!-- SDK条件 -->
    <!--
      ((((开屏 || 插屏) && 开启自渲染) || 原生自渲染 || 混合自渲染) && 部分sdk平台) || 原生自渲染
      -->
    <a-form-model-item prop="hotspot">
      <m-tips
        slot="label"
        :content="`${title}针对自渲染的开屏、插屏、信息流场景，在满足合规要求的前提下，提供了丰富的广告交互样式。${title}版本2.6.5及之上版本支持该配置，部分交互需在SDK版本2.7.5及之上支持。原生混合-自渲染和激励视频样式需要在SDK版本2.7.7及以上支持该功能`"
        :title="'广告交互'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.hotspot" @change="changeHotspot">
        <a-radio value="A"> 开启 </a-radio>
        <a-radio value="S"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 广告交互样式 -->
    <a-form-model-item
      v-if="
        formquery.hotspot === 'A' &&
          ((+platInfo.accessType === 1 &&
          (+positionId === 1 ||
          +positionId === 3 ||
          (+positionId === 10 && (+formquery.adStyle === 1 ||+formquery.adStyle === 2)) ||
          +positionId === 6 || +positionId === 4)) ||
          ((((+positionId === 1 || +positionId === 3) && +formquery.isAutoDrawing === 1) ||
          +positionId === 4 ||
          (+positionId === 10 && +formquery.adStyle === 2)) &&
          (+formquery.platId === 1 ||
          +formquery.platId === 2 ||
          +formquery.platId === 3 ||
          +formquery.platId === 4 ||
          +formquery.platId === 6 ||
          +formquery.platId === 10 ||
          +formquery.platId === 12 ||
          +formquery.platId === 14 ||
          +formquery.platId === 19)) ||
          (+positionId === 10 && +formquery.adStyle === 2 && +formquery.platId === 0) ||
          (+positionId === 5 && +formquery.platId === 0) ||
          (+positionId === 2 && +formquery.platId === 0))
      "
      label="广告交互样式"
      prop="hotspotType"
    >
      <a-radio-group name="radioGroup" v-model="formquery.hotspotType" @change="changeHotspotType">
        <!-- 开屏 -->
        <a-radio :value="1" v-if="+positionId === 1"> 默认 </a-radio>
        <!-- 除了优量汇和快手 -->
        <a-radio :value="2" v-if="![1, 2, 3, 6].includes(+platInfo.id)"> 摇一摇 </a-radio>
        <!-- 开屏 -->
        <a-radio :value="3" v-if="+positionId === 1"> 圆形+触手 </a-radio>
        <!-- 开屏 + api平台 -->
        <a-radio :value="4" v-if="+positionId === 1 && +platInfo.accessType === 1"> 前倾 </a-radio>
        <a-radio :value="5" v-if="+positionId === 1 && +platInfo.accessType === 1 || (+positionId === 2 && +formquery.platId === 0)"> 滑动 </a-radio>
        <a-radio :value="6" v-if="+positionId === 1 && +platInfo.accessType === 1"> 蒙层&摇一摇 </a-radio>
        <a-radio :value="7" v-if="+positionId === 1 && +platInfo.accessType === 1"> 滑动&摇一摇 </a-radio>
        <!-- 自渲染 && api -->
        <a-radio :value="8" v-if="[4, 6, 10].includes(+positionId) && +platInfo.accessType === 1"> 蒙层滑动 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 蒙层显示 -->
    <a-form-model-item
      label="蒙层显示"
      prop="displayMask"
      v-if="
        formquery.hotspot === 'A' &&
          ((+platInfo.accessType === 1 &&
          (+positionId === 1 ||
          +positionId === 3 ||
          (+positionId === 10 && (+formquery.adStyle === 1 ||+formquery.adStyle === 2)) ||
          +positionId === 6)) ||
          ((((+positionId === 1 || +positionId === 3) && +formquery.isAutoDrawing === 1) ||
          +positionId === 4 ||
          (+positionId === 10 && +formquery.adStyle === 2)) &&
          (+formquery.platId === 1 ||
          +formquery.platId === 2 ||
          +formquery.platId === 3 ||
          +formquery.platId === 4 ||
          +formquery.platId === 6 ||
          +formquery.platId === 10 ||
          +formquery.platId === 12 ||
          +formquery.platId === 14 ||
          +formquery.platId === 19)) ||
          (+positionId === 10 && +formquery.adStyle === 2 && +formquery.platId === 0) ||
          (+positionId === 5 && +formquery.platId === 0)) &&
          [4, 6, 10].includes(+positionId) &&
          +platInfo.accessType === 1 &&
          +formquery.hotspotType === 8
      "
    >
      <a-radio-group name="radioGroup" v-model="formquery.displayMask">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 灵敏度 -->
    <a-form-model-item
      v-if="
        formquery.hotspot === 'A' &&
          (formquery.hotspotType === 2 || formquery.hotspotType === 6 || formquery.hotspotType === 7) &&
          (role === 'SUPER_ADMIN' || +sensitivity === 1) &&
          (+platInfo.accessType === 1 ||
          ((+positionId === 1 || +positionId === 3) && +formquery.isAutoDrawing === 1) ||
          (+positionId === 10 && +formquery.adStyle === 1) ||
          +positionId === 6)
      "
      prop="sensitivity"
    >
      <m-tips
        slot="label"
        :content="`灵敏度常规取值范围0-100，不在此范围的数值，摇一摇按照完全合规执行。`"
        :title="'灵敏度'"
        :width="200"
      ></m-tips>
      <a-input-number
        style="width: 200px"
        placeholder="请输入灵敏度"
        :min="0"
        :max="100"
        v-model.trim="formquery.sensitivity"
      />
    </a-form-model-item>
    <!-- 交互样式大小 -->
    <a-form-model-item v-if="+positionId === 1 && formquery.hotspot === 'A'" label="交互样式大小" prop="scaleType">
      <a-radio-group name="radioGroup" v-model="formquery.scaleType">
        <a-radio :value="0"> 默认</a-radio>
        <a-radio :value="1"> 大 </a-radio>
        <a-radio :value="2"> 超大 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { showIsAutoDrawing } from '@/utils/autoLabel'
export default {
  data () {
    return {
      formqueryTemp: {
        hotspot: 'S',
        hotspotType: 1,
        sensitivity: 0,
        scaleType: 0,
        displayMask: 1
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        hotspot: 'S',
        hotspotType: 1,
        sensitivity: 0,
        scaleType: 0,
        displayMask: 1
      }),
      type: Object
    },
    positionId: {
      default: 0,
      type: [Number, String]
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    showIsAutoDrawing,
    changeHotspot (e) {
      if (e.target.value === 'A' && this.positionId === 1) {
        this.formquery.hotspotType = 1
      } else {
        this.formquery.hotspotType = 2
      }
    },
    changeHotspotType (e) {
      this.formquery.hotspotType = e.target.value
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped></style>
