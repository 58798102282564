<template>
  <div v-if="+positionId === 3">
    <!-- 插屏样式 -->
    <a-form-model-item
      prop="drawTypeList"
      v-if="
        (+positionId === 3 && +platInfo.accessType === 1) ||
          (+positionId === 3 &&
          (+platInfo.id === 1 ||
          +platInfo.id === 2 ||
          +platInfo.id === 3 ||
          +platInfo.id === 4 ||
          +platInfo.id === 6 ||
          +platInfo.id === 31 ||
          +platInfo.id === 5) &&
          +formquery.isAutoDrawing === 1 && showIsAutoDrawing(formquery, platInfo, positionId))
      "
    >
      <m-tips
        slot="label"
        :content="`${title}SDK渲染的插屏样式，竖版样式一般为9:16的尺寸比例；方形则接近1:1的尺寸比例。${title} SDK 2.7.1及以上支持。`"
        :title="'插屏样式'"
        :width="200"
      ></m-tips>
      <a-checkbox-group v-model="formquery.drawTypeList">
        <a-checkbox :value="item.id" v-for="item in drawTypeList" :key="item.id">{{ item.name }}</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
    <template
      v-if="
        isAdmin &&
          +positionId === 3 &&
          (+platInfo.accessType === 1 ||
          +platInfo.id === 1019 ||
          (+platInfo.accessType === 0 && +formquery.isAutoDrawing && showIsAutoDrawing(formquery, platInfo, positionId))) &&
          (formquery.drawTypeList || []).find((item) => +item === 2)
      "
    >
      <!-- 动画开始时间 -->
      <a-form-model-item prop="animateStartTime" label="动画开始时间">
        <a-input
          style="width: 250px"
          @input="changeAnimateStartTime"
          placeholder="请输入动画开始时间"
          v-model.trim="formquery.animateStartTime"
        >
          <span slot="addonAfter">ms</span>
        </a-input>
      </a-form-model-item>
      <!-- 动画持续时间 -->
      <a-form-model-item prop="animateTime" label="动画持续时间">
        <a-input
          style="width: 250px"
          @input="changeAnimateTime"
          placeholder="请输入动画持续时间"
          v-model.trim="formquery.animateTime"
        >
          <span slot="addonAfter">s</span>
        </a-input>
      </a-form-model-item>
    </template>
    <template v-if="showIsAutoDrawing(formquery, platInfo, positionId)"></template>
    <!-- 是否渲染统一关闭按钮 -->
    <a-form-model-item
      v-if="
        (+positionId === 3 && isAdmin)
      "
      prop="cpCloseButton"
    >
      <m-tips
        slot="label"
        :content="`开启后，${title}会在展示插屏广告时，额外渲染一个统一的关闭按钮。`"
        :title="'是否渲染统一关闭按钮'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.cpCloseButton">
        <a-radio value="A"> 开启 </a-radio>
        <a-radio value="S"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- title 插屏关闭样式 -->
    <a-form-model-item
      v-if="
        ((+positionId === 3 && +formquery.isAutoDrawing === 1 && +platInfo.accessType === 0 && showIsAutoDrawing(formquery, platInfo, positionId)) ||
          (+positionId === 3 && +platInfo.accessType === 1)) &&
          isAdmin
      "
      prop="closeButtonType"
    >
      <m-tips
        slot="label"
        :content="`控制由${title} SDK渲染的插屏广告中，关闭按钮的样式`"
        :title="`${title}插屏关闭样式`"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.closeButtonType">
        <a-radio value="A"> 一直显示 </a-radio>
        <a-radio value="B"> n秒后显示 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 显示时间 -->
    <a-form-model-item v-if="formquery.closeButtonType === 'B' && isAdmin" label="显示时间" prop="countDown">
      <a-input style="width: 200px" placeholder="请输入显示时间" v-model.trim="formquery.countDown" @input="changeCountDown">
        <span slot="addonAfter">s</span>
      </a-input>
    </a-form-model-item>
    <!-- 按钮位置 -->
    <a-form-model-item
      prop="buttonPosition"
      v-if="
        (+positionId === 3 && +platInfo.accessType === 1) ||
          (+positionId === 3 &&
          (+platInfo.id === 1 ||
          +platInfo.id === 2 ||
          +platInfo.id === 3 ||
          +platInfo.id === 4 ||
          +platInfo.id === 6 ||
          +platInfo.id === 5) &&
          +formquery.isAutoDrawing === 1 && showIsAutoDrawing(formquery, platInfo, positionId))
      "
    >
      <m-tips
        slot="label"
        :content="`${title}渲染的插屏关闭按钮位置，SDK版本2.7.1及以上支持。`"
        :title="'关闭按钮位置'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.buttonPosition">
        <a-radio value="0"> 浮图右上 </a-radio>
        <a-radio value="1"> 图下居中 </a-radio>
        <a-radio value="2"> 图右上角 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 关闭按钮大小 -->
    <a-form-model-item
      v-if="
        ((+positionId === 3 && +formquery.isAutoDrawing === 1 && role === 'SUPER_ADMIN' && showIsAutoDrawing(formquery, platInfo, positionId)) ||
          (+platInfo.accessType === 1 && +positionId === 3 && role === 'SUPER_ADMIN') ||
          (+positionId === 3 && +formquery.isAutoDrawing === 1 && +buttonSize === 1 && showIsAutoDrawing(formquery, platInfo, positionId))) &&
          isAdmin
      "
      label="关闭按钮大小"
      prop="buttonSize"
    >
      <a-input style="width: 200px" placeholder="请输入关闭按钮大小" v-model.trim="formquery.buttonSize" @input="changButtonSize">
        <span slot="addonAfter">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 点击后自动关闭 -->
    <a-form-model-item v-if="+positionId === 3 && isAdmin" prop="autoClose">
      <m-tips
        slot="label"
        :content="`用户点击插屏广告素材之后广告自动关闭，目前仅部分平台支持设置；${title}2.7.4版本及之上支持该功能。`"
        :title="'点击后自动关闭'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.autoClose">
        <a-radio value="A"> 是 </a-radio>
        <a-radio value="S"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 自动关闭倒计时 -->
    <a-form-model-item v-if="+positionId === 3 && isAdmin" prop="closeCountdown">
      <m-tips
        slot="label"
        :content="`插屏广告支持N (N>0) 秒后自动关闭，无需用户手动触发;当N=0时不触发自动关闭逻辑; ${title}版本2.7.5及以上版本支持该功能。`"
        :title="'自动关闭倒计时'"
        :width="200"
      ></m-tips>
      <a-input
        style="width: 200px"
        @input="changeCloseCountdown"
        placeholder="请输入自动关闭倒计时"
        v-model.number.trim="formquery.closeCountdown"
      >
        <span slot="addonAfter">s</span>
      </a-input>
    </a-form-model-item>
    <!-- 倒计时是否显示 -->
    <a-form-model-item v-if="(+positionId === 3 && (+formquery.closeCountdown > 0 || (formquery.closeButtonType === 'B' && +formquery.countDown > 0))) && isAdmin" prop="cpDisplayCountdown">
      <m-tips
        slot="label"
        :content="`启用倒计时自动关闭功能后，您可选择是否在插屏中显示提示用户的倒计时按钮。`"
        :title="'倒计时是否显示'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.cpDisplayCountdown">
        <a-radio :value="1"> 是 </a-radio>
        <a-radio :value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 仅按钮区域可点 -->
    <a-form-model-item
      v-if="
        (+positionId === 3 &&
          (+platInfo.accessType === 1 ||
          +platInfo.id === 1019 ||
          (alliancePlats.includes(+platInfo.id) && +formquery.isAutoDrawing && showIsAutoDrawing(formquery, platInfo, positionId)))) &&
          isAdmin
      "
      prop="onlyButton"
    >
      <m-tips
        slot="label"
        :content="`${title} SDK2.7.5及之上版本支持对插屏点击区域进行限制，可按广告素材类型进行配置，配置后仅广告渲染中的按钮可点击，其他区域不可以点击。`"
        :title="'仅按钮区域可点'"
        :width="200"
      ></m-tips>
      <a-select
        v-model="formquery.onlyButton"
        allowClear
        placeholder="请选择可点类型"
        style="width: 200px"
      >
        <a-select-option :value="0">不限</a-select-option>
        <a-select-option :value="1">仅图片</a-select-option>
        <a-select-option :value="2">仅视频</a-select-option>
        <a-select-option :value="3">图片&视频</a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- 是否全屏 -->
    <a-form-model-item
      v-if="+positionId === 3 "
      prop="isFullScreen"
      label="是否全屏"
    >
      <a-radio-group name="radioGroup" v-model="formquery.isFullScreen">
        <a-radio :value="1"> 是 </a-radio>
        <a-radio :value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { drawTypeList } from '@/data/dataList'
import { showIsAutoDrawing } from '@/utils/autoLabel'
export default {
  name: 'TableScreenSourceForm',
  data () {
    return {
      drawTypeList,
      isCCTV: process.env.VUE_APP_ISCCTV === 'true',
      formqueryTemp: {
        drawTypeList: [],
        animateStartTime: undefined,
        animateTime: undefined,
        closeButtonType: 'A',
        buttonPosition: '0',
        countDown: undefined,
        buttonSize: undefined,
        autoClose: 'S',
        closeCountdown: undefined,
        onlyButton: undefined,
        isFullScreen: 0,
        cpCloseButton: 'S',
        cpDisplayCountdown: 1
      },
      alliancePlats: [1, 2, 3, 6]
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1 || (!this.isCCTV && +this.currentLoginId === 25) || (this.isCCTV && +this.currentLoginId === 2)) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        drawTypeList: [],
        animateStartTime: undefined,
        animateTime: undefined,
        closeButtonType: undefined,
        buttonPosition: undefined,
        countDown: undefined,
        buttonSize: undefined,
        autoClose: 'S',
        closeCountdown: undefined,
        onlyButton: undefined,
        isFullScreen: 0,
        cpCloseButton: 'S',
        cpDisplayCountdown: 1
      }),
      type: Object
    },
    positionId: {
      default: 0,
      type: Number
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    showIsAutoDrawing,
    changeCountDown (e) {
      this.formquery.countDown = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changButtonSize (e) {
      this.formquery.buttonSize = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changeCloseCountdown (e) {
      this.formquery.closeCountdown = +(e.target.value || '').replace(/[^0-9]/g, '') || 0
    },
    changeAnimateTime (e) {
      this.formquery.animateTime = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (this.formquery.animateTime > 10000) {
        this.formquery.animateTime = 10000
      }
    },
    changeAnimateStartTime (e) {
      this.formquery.animateStartTime = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    }
  }
}
</script>

<style lang="less" scoped>
</style>
